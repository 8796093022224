import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import GtrStorage from '@/modules/common/services/storage.service'
import Container from 'typedi'
import { ValidationObserver } from 'vee-validate'
import { Component, Ref, Vue } from 'vue-property-decorator'
import GtrSecurityRegisterLayout from '../../layouts/security/security-register.layout.vue'
import { TwoFactorAuthenticationMethod } from '@/modules/common/enums/two-factor-methods.enum'

@Component({
  name: 'GtrRegisterView'
})
export default class GtrRegisterView extends Vue {
    @Ref()
    readonly observerRegisterForm!: InstanceType<typeof ValidationObserver>

    data () {
      return {
        submitting: false,
        showTermsModal: false,
        register: {
          first_name: null,
          last_name: null,
          email: null,
          password: null,
          password_confirmation: null,
          two_factor_enabled: 1,
          two_factor_method: TwoFactorAuthenticationMethod.EMAIL,
          agreeToTerms: false
        }
      }
    }

    created () {
      this.$emit('update:layout', GtrSecurityRegisterLayout)
    }

    async submit () {
      const formObserver = this.$refs.observerRegisterForm as any
      const isValid = await formObserver.validate()
      if (isValid) {
        try {
          this.$data.submitting = true
          this.$data.register.admin_base_url = window.location.origin
          const response = await this.$store.dispatch('security/register', this.$data.register)
          if (response.data.uuid) {
            Container.get(GtrStorage).setItem('signedup_email', this.$data.register.email)
            this.$data.register = {
              first_name: null,
              last_name: null,
              email: null,
              password: null,
              password_confirmation: null,
              two_factor_enabled: 1,
              two_factor_method: TwoFactorAuthenticationMethod.EMAIL,
              agreeToTerms: false
            }
            this.observerRegisterForm.reset()
            this.$router.push({ name: 'security.check-email' })
          }
        } catch (error) {
          if (error.data?.errors) {
            this.showServerErrors(error.data.errors)
          }
          Container.get(ErrorHandlerService).error(error)
        } finally {
          this.$data.submitting = false
        }
      }
    }

    private showServerErrors (errors: Record<string, string>): void {
      this.observerRegisterForm.setErrors(errors)
    }

    openTermsModal () {
      this.$data.showTermsModal = true
    }

    closeTermsModal () {
      this.$data.showTermsModal = false
    }

    agreeAndCloseTermsModal () {
      this.$data.register.agreeToTerms = true
      this.$data.showTermsModal = false
    }
}
